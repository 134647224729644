<script>
import DetailFormGroup from '@/components/DetailFormGroup'
import { postAction, getAction } from '@/command/netTool'
import { handleDeep3 } from '@/command/dataProcess'
import apiTool from '@/command/apiTool'
import moment from 'moment'

export default {
  name: 'detailCold',
  data() {
    return {
      levelArr: [], //五级联动
      detail: {
        list: [],
      },
    }
  },
  mounted() {
    getAction('/api/dataoperation/region/freeTree?level=4').then((res) => {
      this.levelArr = res && res.length > 0 && handleDeep3(res)
      this.$forceUpdate()
    })
    const { mainId } = this.$route.query
    mainId &&
      getAction(`/api/dataoperation/collectionLk/detailLk/${mainId}`).then((res) => {
        this.setData(res)
      })
  },
  methods: {
    setData(res) {
      const arr = JSON.parse(localStorage.getItem('lkList')) || []
      const { lkInfo } = res
      const { medianEdit } = this.$route.query
      if (!medianEdit) {
        localStorage.setItem('lkList', JSON.stringify(res.lkList))
      }
      if (lkInfo === '1') {
        this.detail = {
          ...res,
          list: medianEdit ? arr : res.lkList,
        }
      } else if (lkInfo === '2') {
        let obj = [...res.lkList, ...arr][0]
        this.detail = {
          ...res,
          ...obj,
          dw: obj.lng && `${obj.lng}，${obj.lat}`,
          list: res.lkList,
          regionCode: [obj.provinceCode, obj.cityCode, obj.areaCode, obj.streetCode, obj.address, obj.remark],
        }
      } else {
        this.detail = {
          ...res,
          list: res.lkList,
        }
      }
      this.$forceUpdate()
    },
    setValue(valKey, val) {
      this.detail[valKey] = val
    },
    getSearch(value = '') {
      getAction(`/api/dataoperation/common/getLocation?location=${value}`).then((e) => {
        const { address, provinceCode, provinceName, cityCode, cityName, areaCode, areaName, streetCode, streetName } =
          e
        this.setValue('address', address)
        this.setValue('areaCode', areaCode)
        this.setValue('areaName', areaName)
        this.setValue('provinceName', provinceName)
        this.setValue('provinceCode', provinceCode)
        this.setValue('cityName', cityName)
        this.setValue('cityCode', cityCode)
        this.setValue('streetCode', streetCode || '')
        this.setValue('streetName', streetName || '')
        this.setValue('regionCode', [provinceCode, cityCode, areaCode, streetCode])
        this.$forceUpdate()
      })
    },
    onOpenMap() {
      apiTool.openTheMap({
        title: '打开地图',
        onSuccess: (data) => {
          this.detail.lat = data.lat
          this.detail.lng = data.lng
          this.detail.dw = `${data.lng}，${data.lat}`
          this.getSearch(`${data.lng},${data.lat}`)
        },
      })
    },
    getForm1() {
      return {
        title: '冷库信息',
        type: 'cardForm',
        data: [
          {
            name: '冷库情况',
            type: 'row',
            cols: 24,
            gutter: 10,
            children: [
              {
                type: 'select',
                label: '冷库情况',
                cols: 12,
                key: 'lkInfo',
                typeData: [
                  {
                    name: '有冷库',
                    value: '1',
                  },
                  {
                    name: '有需求',
                    value: '2',
                  },
                  {
                    name: '无需求',
                    value: '3',
                  },
                ],
                display: true,
              },
              {
                label: '添加冷库',
                type: 'button',
                cols: 2,
                props: {
                  type: 'primary',
                  ghost: true,
                },
                onClick: () => {
                  postAction('/api/dataoperation/collectionLk/saveLk', this.detail).then(() => {
                    this.$router.push({
                      name: 'detailColdAdd',
                      query: {
                        mainId: this.$route.query.mainId,
                        isEdit: 'add',
                        creditCode: this.$route.query.creditCode,
                      },
                    })
                  })
                },
                display: this.detail.lkInfo === '1',
              },
            ].filter((e) => e.display),
          },
        ],
      }
    },
    getForm2() {
      return {
        title: '冷库需求',
        type: 'cardForm',
        data: [
          {
            name: '冷库类型',
            type: 'checkBoxButton',
            cols: 12,
            key: 'storageType',
            typeData: [
              {
                name: '保鲜',
                value: '保鲜',
              },
              {
                name: '冷藏',
                value: '冷藏',
              },
              {
                name: '冷冻',
                value: '冷冻',
              },
            ],
          },
          {
            name: '冷库面积',
            type: 'inputNumber',
            cols: 12,
            key: 'area',
          },
          {
            name: '经纬度',
            type: 'row',
            cols: 12,
            gutter: 5,
            children: [
              {
                type: 'input',
                cols: 21,
                key: 'dw',
                label: '经纬度',
                disabled: true,
                placeholder: '请输入经纬度以中文逗号隔开',
              },
              {
                type: 'button',
                label: '定位',
                cols: 3,
                props: {
                  type: 'primary',
                  icon: 'environment',
                  on: {
                    click: this.onOpenMap,
                  },
                },
              },
            ],
          },
          {
            name: '地址',
            type: 'row',
            cols: 12,
            gutter: 5,
            children: [
              {
                type: 'cascader',
                cols: 10,
                key: 'regionCode',
                typeData: this.levelArr,
                label: '地址',
              },
              {
                type: 'input',
                cols: 14,
                key: 'address',
                label: '地址',
              },
            ],
          },
          {
            label: '冷库需求说明',
            type: 'textArea',
            cols: 24,
            key: 'remark',
          },
        ],
      }
    },
    getForm3() {
      return {
        type: 'table',
        showFootButton: false,
        showRowSelect: false,
        showPagination: false,
        dataSource: this.detail.list || [],
        columns: [
          {
            dataIndex: 'storageName',
            align: 'left',
            title: '冷库名称',
          },
          {
            dataIndex: 'storageType',
            align: 'left',
            title: '冷库类型',
          },
          {
            dataIndex: 'area',
            align: 'left',
            title: '冷库面积（m³）',
          },
          {
            dataIndex: 'updateBy',
            align: 'left',
            title: '最新操作',
            render: ({ text, records }) => text && `${text}  (${moment(records.updateTime).format('YYYY/MM/DD')})`,
          },
          {
            dataIndex: 'action',
            align: 'center',
            width: '10%',
            title: '操作',
            tableType: 'editDelete',
            onEdit: (text, records, index) => {
              localStorage.setItem('lkList', JSON.stringify(this.detail.list))
              this.$router.push({
                name: 'detailColdAdd',
                query: {
                  mainId: this.$route.query.mainId,
                  isEdit: 'edit',
                  dataSource: JSON.stringify(records),
                  indexId: index,
                  creditCode: this.$route.query.creditCode,
                },
              })
            },
            onDel: (text, records, index) => {
              let arr = JSON.parse(localStorage.getItem('lkList'))
              arr.splice(index, 1)
              this.detail.list = arr
              localStorage.setItem('lkList', JSON.stringify(arr))
            },
          },
        ],
      }
    },
    getSubmitData(data) {
      const codeText = ['province', 'city', 'area', 'street']
      let arr = document.getElementsByClassName('ant-cascader-picker-label')[0].innerHTML.split('/')
      const obj = {}
      arr.forEach((e, i) => {
        obj[codeText[i] + 'Name'] = e
        obj[codeText[i] + 'Code'] = data[i]
      })
      return obj
    },
    submit(data) {
      const { lkInfo } = data
      let codeObj = (data.dw && this.getSubmitData(data.regionCode)) || {} //   let categoryList = this.getCategoryList(data)
      let params = {}
      if (lkInfo === '1') {
        params = {
          ...data,
          lkList: data.list || [],
        }
      } else if (lkInfo === '2') {
        // let { storageType, area, lng, lat, provinceCode, cityCode, areaCode, streetCode, address, remark } = data
        params = {
          collectionId: data.collectionId,
          lkInfo: data.lkInfo,
          lkList: [{ ...data, ...codeObj, storageType: data.storageType.toString() }],
        }
      } else {
        params = {
          ...data,
          lkList: [],
        }
      }

      postAction('/api/dataoperation/collectionLk/saveLk', {
        ...params,
      }).then(() => {
        this.$router.push({ name: 'basicInformation' })
      })
    },
    getFoot() {
      return {
        left: [
          {
            name: '提交',
            sumbit: true,
            type: 'primary',
            onClick: this.submit,
          },
          {
            name: '取消',
            onClick: () => {
              this.$router.back()
            },
          },
        ],
      }
    },
  },
  render() {
    return (
      <DetailFormGroup
        form={this.detail}
        foot={this.getFoot()}
        data={[
          this.getForm1(),
          this.detail.lkInfo === '2' && this.getForm2(),
          this.detail.lkInfo === '1' && this.getForm3(),
        ]}
      />
    )
  },
}
</script>
<style lang="less" scoped></style>
